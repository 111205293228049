export const PRODUCT_NAME = 'RAFFLE';

// APIのレスポンスのcode
export const API_RESPONSE_CODE = {
  success: { value: 0 },
  error: { value: 1 },
  smsRequired: { value: 2 },
  banUser: { value: 3 },
  httpUnprocessableEntity: { value: 422 },
};

// 誕生日の表示種別
export const BIRTHDAY_SHOW_TYPE = {
  full: { text: '公開', format: 'YYYY.MM.DD' },
  partial: { text: '月日のみ公開', format: 'MM.DD' },
  hidden: { text: '非公開', format: '' },
};

// メッセージボックスの表示対象
export const MESSAGE_TARGET_TYPE = {
  all: { text: 'すべて', value: 'all' },
  follow: { text: 'フォロー', value: 'follow' },
  limited: { text: '限定', value: 'limited' },
};

// 決済の種別
export const ITEM_TYPE = {
  coin: 'coin',
  oemSubscription: 'subscription',
  artistSubscription: 'artist_subscription',
};

// 通知パーミッション
export const NOTIFICATION_PERMISSION = {
  1: { text: '新着投稿' }, // プッシュ通知
  2: { text: '新着記事' }, // プッシュ通知
  4: { text: '新着メッセージ' }, // プッシュ通知
  8: { text: '新着投稿' }, // メール
  32: { text: '新着記事' }, // メール
  128: { text: '新着メッセージ' }, // メール
};

// メルマガ通知パーミッション
export const NEWSLETTER_PERMISSION = {
  1: { text: 'メルマガ' }, // プッシュ通知
  2: { text: 'メルマガ' }, // メール
};

// 配送種別
export const DELIVERY_TYPE = {
  delivery: { value: 'delivery' },
  hand_over: { value: 'hand_over' },
};

// 配送ステータス
export const DELIVERY_STATUS = {
  unprocessed: {
    text: '準備中',
    value: 'unprocessed',
    color: 'var(--color-black)',
  },
  preparing: {
    text: '準備中',
    value: 'preparing',
    color: 'var(--color-black)',
  },
  inprocess: {
    text: '準備中',
    value: 'inprocess',
    color: 'var(--color-black)',
  },
  shipped: {
    text: '発送済',
    value: 'shipped',
    color: 'var(--color-black)',
  },
  complete: {
    text: 'お届け済',
    value: 'complete',
    color: 'var(--color-warmgray)',
  },
  no_address: {
    text: '住所未登録',
    value: 'no_address',
    color: '',
  },
  return: { text: '返品', value: 'return', color: 'var(--color-black)' },
};

// くじイベント開催ステータス
export const LOTTERY_STATUS = {
  upcoming: { text: '開催予定', value: 'upcoming', labelColor: 'black' },
  is_session: { text: '開催中', value: 'is_session', labelColor: 'primary' },
  later: { text: '終了', value: 'later', labelColor: 'lightgray' },
};

// くじ景品ラベル種別
export const LOTTERY_PRIZE_TYPE = {
  image: { text: '画像', value: 'image' },
  video: { text: '動画', value: 'video' },
  audio: { text: '音声', value: 'audio' },
  text: { text: 'テキスト', value: 'text' },
  goods: { text: '物', value: 'goods' },
};

// くじ景品ダウンロードステータス
export const LOTTERY_DOWNLOAD_STATUS = {
  disabled: { text: 'ダウンロード不可', value: 'disabled' },
  pending: { text: 'ダウンロード待ち', value: 'pending' },
  available: { text: 'ダウンロード可能', value: 'available' },
};

// 在庫僅か
export const LOTTERY_STOCK_FEW = 10;

// 獲得景品の住所未入力状態
export const SHIPPING_OVER_TYPE = {
  reception: { text: '受付中', value: 'reception' },
  over: { text: '入力期限切れ', value: 'over' },
  ok: { text: '入力済み', value: 'ok' },
};

// くじ引き制限種別
export const LOTTERY_DRAW_TYPE = {
  unlimited: { text: '無制限', value: 'unlimited' },
  only_serial_code: { text: 'シリアルコードのみ', value: 'only_serial_code' },
  first_serial_code: {
    text: '初回シリアルコード必須',
    value: 'first_serial_code',
  },
};

// くじ引き
export const LOTTERY_SEARCH_TYPE = {
  goods: { text: '景品別', value: 'goods' },
  lottery: { text: 'イベント別', value: 'lottery' },
};

// 購入プラットフォーム
export const PLATFORM = {
  ios: { text: 'iOS', value: 'ios' },
  android: { text: 'Android', value: 'android' },
  web: { text: 'Web', value: 'web' },
};

// 履歴種別
export const HISTORY_TYPE = {
  coin: { text: 'コイン', value: 'coin' },
  code: { text: 'シリアルコード', value: 'code' },
};

// Wチャンス種別
// manual:手動(旧Wチャンス), direct:即時抽選, batch:一括抽選
export const WCHANCE_TYPE = {
  manual: 'manual',
  direct: 'direct',
  batch: 'batch',
};

// Wチャンスラベルステータス
export const WCHANCE_LABEL_STATUS = {
  is_session: { text: '対象商品', color: 'primary' },
  soon_start: { text: 'まもなく開始', color: 'cyan' },
  finish: { text: '終了', color: 'black' },
  sold_out: { text: '完売', color: 'black' },
};

// Wチャンス抽選ステータス
export const WCHANCE_DRAW_STATUS = {
  won: { key: 'won', text: '当選', color: 'primary' },
  pending: { key: 'pending', text: '', color: '' },
  submitted: { key: 'submitted', text: '抽選待ち', color: 'black' },
  lost: { key: 'lost', text: '落選', color: 'black' },
};

// Wチャンス履歴種別
export const WCHANCE_COLLECTION_TYPE = {
  label: { text: 'Wチャンス賞別', value: 'label' },
  panding: { text: '未実施一覧', value: 'pending' },
};

// Wチャンスアンケートステータス
export const WCHANCE_SURVEY_STATUS = {
  // 未回答
  not_comp: {
    key: 'not_comp',
    linkText: 'アンケートに答えてWチャンス賞にチャレンジ',
  },
  // 回答不要
  no_need_comp: {
    key: 'no_need_comp',
    linkText: 'Wチャンス賞にチャレンジ',
  },
  // 回答済み
  comp: {
    key: 'comp',
    linkText: 'Wチャンス賞にチャレンジ',
  },
};

// SNS種別
export const SNS_TYPE = {
  x: { value: 'x' }, //旧ツイッター
  instagram: { value: 'instagram' },
  tiktok: { value: 'tiktok' },
  youtube: { value: 'youtube' },
};

// クリエイター検索種別
export const CREATOR_SEARCH_TYPE = {
  creator: { text: 'アーティスト', value: 'creator' },
  team: { text: 'グループ', value: 'team' },
};
