import styles from './ErrorPage.module.scss';
import { montserrat } from '@/lib/font';
import utilsStyles from '@/styles/modules/utils.module.scss';

function ErrorPage({
  statusCode,
  name,
  description,
}: {
  statusCode: number;
  name: string;
  description: string;
}) {
  return (
    <div className={styles.componentStyle}>
      <div className={styles.errorCode}>
        <p className={montserrat.className}>{statusCode}</p>
      </div>
      <div className={styles.errorText}>
        <p className={montserrat.className}>{name}</p>
      </div>
      <div className={utilsStyles.mtMedium}>
        <p className={`${utilsStyles.textPrewrap} ${utilsStyles.textSmall}`}>{description}</p>
      </div>
    </div>
  );
}

export default ErrorPage;
