import { UrlObject } from 'url';
import { Route } from 'next';
import Link from 'next/link';
import styles from './LinkButton.module.scss';

function LinkButton<T extends string>({
  children,
  href,
  size = 'default',
  color = 'black',
  bgColor = 'white',
  outlined = false,
}: {
  children: React.ReactNode;
  href: Route<T> | UrlObject;
  size?: 'xSmall' | 'default' | 'small' | 'large' | 'xLarge' | 'fullWidth';
  /** 参照: src/styles/partials/_variables.scss */
  color?: string;
  bgColor?: string;
  outlined?: boolean;
}) {
  return (
    <div className={`${styles.linkButton} ${styles[size]}`} style={generateStyle({ color, bgColor, outlined })}>
      <Link href={href} prefetch={false} className={styles.link}>
        {children}
      </Link>
    </div>
  );
}

const generateStyle = ({
  color,
  bgColor,
  outlined,
}: {
  color?: string;
  bgColor?: string;
  outlined?: boolean;
}): React.CSSProperties => {
  if (outlined) {
    return {
      color: `var(--color-${color})`,
      border: `2px solid var(--color-${color})`,
      background: `var(--color-${bgColor})`,
    };
  }

  // 色に応じてグラデーション
  switch (color) {
    case 'primary':
      return {
        border: 'none',
        background: 'linear-gradient(to top, #e11111, var(--color-primary))',
      };
    case 'blue-gradient':
      return {
        border: 'none',
        background: 'linear-gradient(to top, #1190e0, #33b0ff)',
      };
    default:
      return {
        border: `var(--color-${color})`,
        background: `var(--color-${color})`,
      };
  }
};

export default LinkButton;
