'use client'; // Error components must be Client components

import ErrorPage from '@/components/errors/ErrorPage/ErrorPage';
import LinkButton from '@/components/form/LinkButton/LinkButton';
import CommonFooter from '@/components/layouts/CommonFooter/CommonFooter';
import CommonHeader from '@/components/layouts/CommonHeader/CommonHeader';
import { PRODUCT_NAME } from '@/constants';
import MenuWrapper from '@/features/auth/components/MenuWrapper/MenuWrapper';
import '@/styles/global.scss';
import layoutStyles from '@/styles/modules/layout.module.scss';
import utilsStyles from '@/styles/modules/utils.module.scss';
import '@/styles/reset.scss';
import * as Sentry from '@sentry/nextjs';

// biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
export default function Error({ error }: { error: Error }) {
  if (error) {
    Sentry.captureException(error);
    Sentry.flush(2000);
  }
  return (
    <>
      <CommonHeader siteName={PRODUCT_NAME}></CommonHeader>
      <MenuWrapper></MenuWrapper>
      <div className={layoutStyles.pageWrapper}>
        <div className={layoutStyles.page}>
          <main>
            <div className={layoutStyles.pageContainer}>
              <div className={utilsStyles.mtSmall}>
                <ErrorPage statusCode={500} name={'SERVER ERROR'} description={`エラーが発生しました。`}></ErrorPage>
              </div>
              <div className={`${layoutStyles.centeredBtnWrapper} ${utilsStyles.mtMedium}`}>
                <LinkButton href={'/'}>トップページへ</LinkButton>
              </div>
            </div>
          </main>
        </div>
      </div>
      <CommonFooter siteName={PRODUCT_NAME} copyright={'Copyrights 2021, Fogg Inc.All rights reserved.'}></CommonFooter>
    </>
  );
}
